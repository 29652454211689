<template>
  <div>
    <b-container>
      <b-card class="my-4 pb-0">
        <b-row>
          <b-col md="9">
        <img :src="eventsInfo.featured_image" class="w-100">
        <div>
          <div class="mt-4 header-horizontal-title position-relative">
            <h4 class="font-weight-bold position-relative text-primary py-1">
              اسم الفاعلية
            </h4>
          </div>
          <p class="font-size-18 text-primary font-weight-bold text-justify mt-3">{{eventsInfo.title}}</p>
        </div>
        <p class="font-size-18 text-justify mt-3">{{eventsInfo.content}}</p>
        <p class="font-size-18 text-justify mt-3">{{eventsInfo.excerpt}}</p>
            <div class="d-flex justify-content-around align-items-center mt-5">
              <b-button
                  v-if="eventsInfo.prev"
                  @click="$router.push({ name: 'eventsDetails', params: { eventsId: eventsInfo.prev.id } })"
                  variant="outline-primary" class="py-2 px-4 iq-border-radius-5">
                <span><i class="las la-angle-double-right"></i></span>
                <span>الخبر السابق</span>
              </b-button>
              <b-button v-if="eventsInfo.next"
                        @click="$router.push({ name: 'eventsDetails', params: { eventsId: eventsInfo.next.id } })"
                        variant="primary" class="py-2 px-4 iq-border-radius-5">
                <span>الخبر التالى</span>
                <span><i class="las la-angle-double-left"></i></span>
              </b-button>
            </div>
        </b-col>
        <b-col md="3" class="mt-4">
            <h4 class="text-primary mb-4">{{ $t('endUser.events') }} الأكثر انتشارا</h4>
            <router-link :to="{name:'eventsDetails', params:{eventsId: item.id}}" class="border-1" v-for="(item ,key) in mostEventsData" :key="key">
              <div>
                <img :src="item.featured_image" class="w-100">
                <!--                <section v-if="type !== 'video'" :style="{-->
                <!--              'background-size': 'cover',-->
                <!--              'background-position': 'center',-->
                <!--              'background-image':-->
                <!--           'url(' + item.featured_image + ')' }"-->
                <!--                         class="w-100 h-250px position-relative cursor-pointer">-->
                <!--                  <div class="overlay-image" v-if="type ==='image'">-->
                <!--                    +{{ item.media_count }} صوره-->
                <!--                  </div>-->
                <!--                </section>-->
                <!--                  <iframe style="width: 100%" height="200" src="https://www.youtube-nocookie.com/embed/gEDcKhhxUZU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                <h5 class="py-2">{{ item.title }}</h5>
                <p>{{item.content.length > 37 ? item.content.substring(0, 37) + '...' : item.content}}</p>
                <p>{{item.excerpt.length > 37 ? item.excerpt.substring(0, 37) + '...' : item.excerpt}}</p>
              </div>
            </router-link>
        </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import homeServices from '@/modules/primaryPages/home/services/home'

export default {
  name: 'eventDetails',
  data () {
    return {
      eventsInfo: [],
      mostEventsData: []
    }
  },
  mounted () {
    core.index()
  },
  watch: {
    '$route' () {
      this.eventDetails()
    }
  },
  methods: {
    eventDetails () {
      this.loadingProductInfo = true
      homeServices.eventDetails(this.$route.params.eventsId).then(res => {
        this.eventsInfo = res.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    },
    mostEvents () {
      this.loadingProductInfo = true
      homeServices.getMostEvents().then(res => {
        this.mostEventsData = res.data.data
      }).finally(() => {
        this.loadingProductInfo = false
      })
    }
  },
  created () {
    this.eventDetails()
    this.mostEvents()
  }
}
</script>

<style>
</style>
